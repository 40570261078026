import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CareerServiceService } from 'src/app/career-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  @ViewChild('jobModal', { static: false }) jobModal;

  contact_form = new FormGroup({
    id: new FormControl(''),
    designation: new FormControl(''),
    location: new FormControl(''),
    experience: new FormControl(''),
    keyskill: new FormControl(''),
    date: new FormControl(''),
    department: new FormControl(''),
    is_Active: new FormControl('')
  })

  update: boolean = false;

  mycareerdat: any;
  modaldisable: boolean = false;
  carrerId: any;
  activeStatus:boolean = false;

  constructor(
    private _httpclientdata: CareerServiceService,
    private router: Router
  ) { }



  ngOnInit() {
    this.getCareer();

    // var modal = document.getElementById("myModal");

    // var btn = document.getElementById("myBtn");
    // var close = document.getElementById("closeme");
    // btn.onclick = function () {
    //   modal.style.display = "block";
    // }
    // close.onclick = function () {
    //   modal.style.display = "none";
    // }
    // window.onclick = function (event) {
    //   if (event.target == modal) {
    //     modal.style.display = "none";
    //   }
    // }
  }

  create() {
    this.update = false;
    this.contact_form.reset();
  }

  edit(data: any) {
    this.update = true;
    console.log('edit career form value', data);
    this.contact_form.controls['id'].setValue(data.id);
    this.contact_form.controls['designation'].setValue(data.desc);
    this.contact_form.controls['location'].setValue(data.location);
    this.contact_form.controls['experience'].setValue(data.Experience);
    this.contact_form.controls['keyskill'].setValue(data.keyskill);
    this.contact_form.controls['date'].setValue(data.date);
    this.contact_form.controls['department'].setValue(data.department);
    this.contact_form.controls['is_Active'].setValue(true ? data.is_active == 1 : false);
    if(data.is_active == 1){
      this.activeStatus = true;
    }else if(data.is_active == 0){
      this.activeStatus = false;
    }
  }
  getCareer() {
    this._httpclientdata.careerdata()
      .subscribe((data: any) => {
        console.log('data', data);
        this.mycareerdat = data;
        // var Data = 
        // this.mycareerdat = JSON.parse(data);
        // console.log('data ', this.mycareerdat);
      }
      )
  }

  deleteCareer(id: any) {
    console.log('delete career id ', id);
    this._httpclientdata.deleteCareer(id)
      .subscribe((res: any) => {
        this.getCareer();
      })
  }

  createCareer() {

    // this.jobModal.hide();
    //   this.modaldisable = true;
    //    $('#myModal').click(function() {
    //     $('#myModal').modal('hide');
    // });
    // $('#myModal').modal('hide')
    // console.log('modal', document.getElementById("myModal"));
    // var modal = document.getElementById("myModal");
    // console.log('modal', modal);
    // modal.style.display = "block";
    this._httpclientdata.postCareer(this.contact_form.value)
      .subscribe((res: any) => {
        console.log('create career response', res);
        $(document).ready(function () {
          $("#myModal").click();  
        });
        this.getCareer();
        this.contact_form.reset();
      })
  }

}
