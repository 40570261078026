import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rewardprogramm',
  templateUrl: './rewardprogramm.component.html',
  styleUrls: ['./rewardprogramm.component.css']
})
export class RewardprogrammComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
