import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { CareerServiceService } from '../../career-service.service';

@Component({
  selector: 'app-store-locator',
  templateUrl: './store-locator.component.html',
  styleUrls: ['./store-locator.component.css']
})
export class StoreLocatorComponent implements OnInit {

  @ViewChild('mapRef', { static: true }) mapElement: ElementRef;

  Markers = [{ 'title': 'HEAD OFFICE', 'lat': '28.5300001044904', 'lng': '77.0783844153435', 'description': '<h4>HEAD OFFICE</h4><p>Khasra No-556, Bijwasan Road, Najafgarh Kapashera Rd, Kapas Hera, Delhi</p></br><a target="_Blank" href="https://goo.gl/maps/ivnz7zkBV2Pkih6T9">Get Direction</a>', 'City': 'HEAD OFFICE', 'State': 'NEW DELHI' },
  { 'title': 'ARARIA', 'lat': '26.1279769877957', 'lng': '87.4687939763715', 'description': '<h4>ARARIA</h4><p>FM Complex, Hospital Road, Araria</p></br><a target="_Blank" href="https://goo.gl/maps/4cGiH9pdcwwxJJda6">Get Direction</a>', 'City': 'ARARIA', 'State': 'Bihar' },
  { 'title': 'BARBIGHA', 'lat': '25.2109467627187', 'lng': '85.7396943956796', 'description': '<h4>BARBIGHA</h4><p>Khata No.179, Khasra No.406, Hatiya More Near Old Petrol Pump Barbigha </p></br><a target="_Blank" href="https://goo.gl/maps/PGAtsASL3BQ427mP6">Get Direction</a>', 'City': 'BARBIGHA', 'State': 'Bihar' },
  { 'title': 'BARH', 'lat': '25.4703662308162', 'lng': '85.7086900288012', 'description': '<h4>BARH</h4><p>Barh Station Road, Near Vishwakarma Mandir, Barh</p></br><a target="_Blank" href="https://goo.gl/maps/F7B73Rut7T1bJJkE9">Get Direction</a>', 'City': 'BARH', 'State': 'Bihar' },
  { 'title': 'BHABUA', 'lat': '25.0407624350288', 'lng': '83.5995570821838', 'description': '<h4>BHABUA</h4><p>Lal Tower, Ward  Number 4, Near Patel Chowk</p></br><a target="_Blank" href="https://goo.gl/maps/3gZ54Jo3C5tebxqZ6">Get Direction</a>', 'City': 'BHABUA', 'State': 'Bihar' },
  { 'title': 'BHAGALPUR', 'lat': '25.2412038909776', 'lng': '86.9625173110237', 'description': '<h4>BHAGALPUR</h4><p>Lal Kothi, Lower Nath Nagar, Bhagalpur</p></br><a target="_Blank" href="https://goo.gl/maps/GNWQiSfgwWLmwJ4o9">Get Direction</a>', 'City': 'BHAGALPUR', 'State': 'Bihar' },
  { 'title': 'DALSINGH SARAI', 'lat': '25.6624550066357', 'lng': '85.8334682128834', 'description': '<h4>DALSINGH SARAI</h4><p>Ward No. 4, Mohalla Chak Nawada , N.H Chowk, Dalsinghsarai, Samastipur</p></br><a target="_Blank" href="https://goo.gl/maps/18rv9SsHgUQBTvEH8">Get Direction</a>', 'City': 'DALSINGH SARAI', 'State': 'Bihar' },
  { 'title': 'KATIHAR', 'lat': '25.5440150477535', 'lng': '87.5737561821939', 'description': '<h4>KATIHAR</h4><p>DR. RAJENDRA PRASAD ROAD, INFRONT OF GURUDWARA,NEW MARKET</p></br><a target="_Blank" href="https://goo.gl/maps/ZQMVb4p7mH3EKk4Z6">Get Direction</a>', 'City': 'KATIHAR', 'State': 'Bihar' },
  { 'title': 'MAHUA', 'lat': '25.8814892931238', 'lng': '85.3969494584693', 'description': '<h4>MAHUA</h4><p>Near M K Pharma, Mahua Police Station, Jawahar Chowk, Hajipur Road, Mahua</p></br><a target="_Blank" href="https://goo.gl/maps/Wh4bGEyesaRDvoJYA">Get Direction</a>', 'City': 'MAHUA', 'State': 'Bihar' },
  { 'title': 'MUZAFFARPUR', 'lat': '26.1311202958952', 'lng': '85.3881983552213', 'description': '<h4>MUZAFFARPUR</h4><p>No-413 Krishna Building, Akharaghat Road, Akharaghat</p></br><a target="_Blank" href="https://goo.gl/maps/nDajB8LiBFoc9Uz97">Get Direction</a>', 'City': 'MUZAFFARPUR', 'State': 'Bihar' },
  { 'title': 'PATNA-4', 'lat': '25.5802007655728', 'lng': '85.1470892747007', 'description': '<h4>PATNA-4</h4><p>Khata No-141,S.M. Complex,Jaganpura,Main By Pass Road,Patna</p></br><a target="_Blank" href="https://goo.gl/maps/XdmMfgGj53y6mEoK6">Get Direction</a>', 'City': 'PATNA-4', 'State': 'Bihar' },
  { 'title': 'SASARAM', 'lat': '25.0744399987991', 'lng': '84.0201143481804', 'description': '<h4>SASARAM</h4><p>Near Sahu Cinema, Station Road, Rohtas</p></br><a target="_Blank" href="https://goo.gl/maps/oo313pT5VvhKk2Gg7">Get Direction</a>', 'City': 'SASARAM', 'State': 'Bihar' },
  { 'title': 'SUPAUL', 'lat': '26.1211511107091', 'lng': '86.6019194128929', 'description': '<h4>SUPAUL</h4><p>Ward No.4 ,Hathkola Road, Lohia Nagar, Supaul</p></br><a target="_Blank" href="https://goo.gl/maps/h7KrsezGj3iZL4f39">Get Direction</a>', 'City': 'SUPAUL', 'State': 'Bihar' },
  { 'title': 'ROSERA', 'lat': '25.7547237603669', 'lng': '86.0276064975417', 'description': '<h4>ROSERA</h4><p>Khasra No-1748, Near Ambedkar Chowk, Rosera</p></br><a target="_Blank" href="https://goo.gl/maps/JTdZmtTttv26MtgD8">Get Direction</a>', 'City': 'ROSERA', 'State': 'Bihar' },
  { 'title': 'JAHANABAD', 'lat': '25.2250693947778', 'lng': '84.9938737237468', 'description': '<h4>JAHANABAD</h4><p>Near Siya Babu Petrol Pump, Patna Gaya Road, Jahanabad</p></br><a target="_Blank" href="https://goo.gl/maps/p8ep7e3z4LgkVuor8">Get Direction</a>', 'City': 'JAHANABAD', 'State': 'Bihar' },
  { 'title': 'TRIVENIGANJ', 'lat': '26.1610749282933', 'lng': '86.8815677481061', 'description': '<h4>TRIVENIGANJ</h4><p>Triveniganj Laal Patti, Main Road, D.S.P Awaas ke bagal mei, Triveniganj</p></br><a target="_Blank" href="https://goo.gl/maps/Hx5ZDKGLn1PRSpjk6">Get Direction</a>', 'City': 'TRIVENIGANJ', 'State': 'Bihar' },
  { 'title': 'MUNGER', 'lat': '25.3719084338386', 'lng': '86.4731756686981', 'description': '<h4>MUNGER</h4><p>YASHWANT COMPLEX,BARI BAZAR,MAIN ROAD ,NEAR KASTURBA WATER WORK,MUNGER</p></br><a target="_Blank" href="https://goo.gl/maps/jLfiDVN8qQyFLymq8">Get Direction</a>', 'City': 'MUNGER', 'State': 'Bihar' },
  { 'title': 'TEKARI', 'lat': '24.9378075407203', 'lng': '84.8497030686894', 'description': '<h4>TEKARI</h4><p>MOHALLA- SALEMPUR, TEKARI-PANCHANPUR ROAD, NEAR WOMENS COLLEGE, TEKARI, GAYA</p></br><a target="_Blank" href="https://goo.gl/maps/GSxVUF3HJPfQ3XJn8">Get Direction</a>', 'City': 'TEKARI', 'State': 'Bihar' },
  { 'title': 'PURNEA', 'lat': '25.774042305571', 'lng': '87.4712692821986', 'description': '<h4>PURNEA</h4><p>Ward No-14, Bardwan Hata, Near Taxi Stand, Purnia</p></br><a target="_Blank" href="https://g.page/NysaaretailPvtltd?share">Get Direction</a>', 'City': 'PURNEA', 'State': 'Bihar' },
  { 'title': 'KAHALGAON', 'lat': '', 'lng': '', 'description': '<h4>KAHALGAON</h4><p>Word No-6, Vikramshila Nagar, Kalpana Cinema Road, Near Bandhan Bank, Kahalgaon</p></br><a target="_Blank" href="https://goo.gl/maps/gtDgbxgZUAZH5xDs8">Get Direction</a>', 'City': 'KAHALGAON', 'State': 'Bihar' },
  { 'title': 'BIHTA', 'lat': '25.6349608551032', 'lng': '84.8772159695498', 'description': '<h4>BIHTA</h4><p>Near Raymond Showroom, Shree Rampur Tola, Main Road, Bihta</p></br><a target="_Blank" href="https://goo.gl/maps/jCwWXABH8GnmrzdR6">Get Direction</a>', 'City': 'BIHTA', 'State': 'Bihar' },
  { 'title': 'AMBIKAPUR', 'lat': '25.6324845970622', 'lng': '84.8882022974984', 'description': '<h4>AMBIKAPUR</h4><p>Shivdhari Arcade, Near Old Bus Stand, Ambikapur, Chhattisgarh</p></br><a target="_Blank" href="https://goo.gl/maps/1SpBcyjeiCacqfba8">Get Direction</a>', 'City': 'AMBIKAPUR', 'State': 'Chhattisgarh' },
  { 'title': 'RAIGARH', 'lat': '21.9046990254512', 'lng': '83.3921870263045', 'description': '<h4>RAIGARH</h4><p>Near Syam Petrol Pump,  Dhimrapur Rd, Laxmipur</p></br><a target="_Blank" href="https://goo.gl/maps/6FoccdwY2i9VCqrc7">Get Direction</a>', 'City': 'RAIGARH', 'State': 'Chhattisgarh' },
  { 'title': 'KORBA', 'lat': '22.3606860266524', 'lng': '82.7221386551485', 'description': '<h4>KORBA</h4><p>Niharika, Maharana Pratap Nagar, Korba, Chhattishgarh</p></br><a target="_Blank" href="https://goo.gl/maps/EZrDQH2AoYhef4UV9">Get Direction</a>', 'City': 'KORBA', 'State': 'Chhattisgarh' },
  { 'title': 'CHATRA', 'lat': '24.2067333503772', 'lng': '84.872428397511', 'description': '<h4>CHATRA</h4><p>Jag Shobha Complex, Near HDFC Bank, Old Court Road</p></br><a target="_Blank" href="https://goo.gl/maps/5TbCnuvTFuPwLdGf7">Get Direction</a>', 'City': 'CHATRA', 'State': 'Jharkhand' },
  { 'title': 'DUMKA', 'lat': '24.265652446183', 'lng': '87.2483189686763', 'description': '<h4>DUMKA</h4><p>MOUZA -DUMKA TOWN NO.-7, THANA ROAD</p></br><a target="_Blank" href="https://goo.gl/maps/CEysd5y9oxTYLPg37">Get Direction</a>', 'City': 'DUMKA', 'State': 'Jharkhand' },
  { 'title': 'GODDA', 'lat': '24.8101625476008', 'lng': '87.2134089454275', 'description': '<h4>GODDA</h4><p>SH-16, Near Sarkanda Chowk, Baldia, Godda</p></br><a target="_Blank" href="https://goo.gl/maps/Hz2jTGeL46t5Zxci9">Get Direction</a>', 'City': 'GODDA', 'State': 'Jharkhand' },
  { 'title': 'GUMLA', 'lat': '23.0423227877346', 'lng': '84.5397103686532', 'description': '<h4>GUMLA</h4><p>MS Tower, Mahaveer Chowk, Bus Stand Road</p></br><a target="_Blank" href="https://goo.gl/maps/xHVEfgB8XnjTgi3B9">Get Direction</a>', 'City': 'GUMLA', 'State': 'Jharkhand' },
  { 'title': 'KHUNTI', 'lat': '23.0870170434982', 'lng': '85.2828031706893', 'description': '<h4>KHUNTI</h4><p>Jagdish Complex Main Road Near Baghpath Singh Chowk Kunti </p></br><a target="_Blank" href="https://goo.gl/maps/qQLvVvNZ5ZoJ23y46">Get Direction</a>', 'City': 'KHUNTI', 'State': 'Jharkhand' },
  { 'title': 'KODERMA', 'lat': '24.4385843094226', 'lng': '85.539099782172', 'description': '<h4>KODERMA</h4><p>Ranchi - Patna Rd, Jhumri Telaiya</p></br><a target="_Blank" href="https://goo.gl/maps/TC2pxZ1AMot79jpN8">Get Direction</a>', 'City': 'KODERMA', 'State': 'Jharkhand' },
  { 'title': 'MADHUPUR', 'lat': '24.2658315077568', 'lng': '86.647030826348', 'description': '<h4>MADHUPUR</h4><p>OPP-ORINTAL BANK OF COMMERCE, BHAGAT SINGH CHOWK</p></br><a target="_Blank" href="https://goo.gl/maps/rVC1NtUeCvAHVH5x7">Get Direction</a>', 'City': 'MADHUPUR', 'State': 'Jharkhand' },
  { 'title': 'RANCHI', 'lat': '23.4912642113881', 'lng': '85.2247042360257', 'description': '<h4>RANCHI</h4><p>Khuti Road-1, Birsa Chowk, Hawai Nagar, Ranchi</p></br><a target="_Blank" href="https://goo.gl/maps/eedawvGqkqThQVmU8">Get Direction</a>', 'City': 'RANCHI', 'State': 'Jharkhand' },
  { 'title': 'SARIKELA', 'lat': '22.7033004159902', 'lng': '85.9276431551547', 'description': '<h4>SARIKELA</h4><p>Near Saaptahik Hot, Garden Inn Restaurant, Saraikela-Kharsawan Main road, SaraiKela</p></br><a target="_Blank" href="https://goo.gl/maps/KRzxd1gU6y89vCPSA">Get Direction</a>', 'City': 'SARIKELA', 'State': 'Jharkhand' },
  { 'title': 'DALTONGANJ', 'lat': '24.0634572211537', 'lng': '84.0789087025016', 'description': '<h4>DALTONGANJ</h4><p>Ranhi Road, Redma Chowk, Thana Medininagar, PO Daltonganj</p></br><a target="_Blank" href="https://goo.gl/maps/rTGMPYWzdKf9sGeL7">Get Direction</a>', 'City': 'DALTONGANJ', 'State': 'Jharkhand' },
  { 'title': 'LOHARDAGA', 'lat': '23.4265906185758', 'lng': '84.6783310398245', 'description': '<h4>LOHARDAGA</h4><p>Near MISSION CHOWK, Lohardaga</p></br><a target="_Blank" href="https://goo.gl/maps/9PWLNXFLUQ9DfNdYA">Get Direction</a>', 'City': 'LOHARDAGA', 'State': 'Jharkhand' },
  { 'title': 'GARHWA', 'lat': '24.183884816239', 'lng': '83.7816207840182', 'description': '<h4>GARHWA</h4><p>Shaurya Complex, Ranka Mod, Near Hero Showroom, Gadhwa</p></br><a target="_Blank" href="https://goo.gl/maps/UwbhUD32grTjcgHM9">Get Direction</a>', 'City': 'GARHWA', 'State': 'JHARKHAND' },
  { 'title': 'BHOPAL ', 'lat': '23.2568704113669', 'lng': '77.4298219974931', 'description': '<h4>BHOPAL </h4><p>Shop No 17-18, Sector c Inderpuri, In Front Of BHEl Gate No 3, Raisen Road, Ashoka Garden</p></br><a target="_Blank" href="https://goo.gl/maps/Cft9cdaJN9RPnV8g7">Get Direction</a>', 'City': 'BHOPAL ', 'State': 'MP' },
  { 'title': 'WAIDHAN', 'lat': '24.0818891928351', 'lng': '82.6335782323086', 'description': '<h4>WAIDHAN</h4><p>PLOT NO-766/1, VINDHYA NAGAR ROAD,WAIDHAN,SINGRAULI,MADHYA PRADESH</p></br><a target="_Blank" href="https://goo.gl/maps/bFvHfqCJHWgkSBMU7">Get Direction</a>', 'City': 'WAIDHAN', 'State': 'MP' },
  { 'title': 'KHORDHA', 'lat': '20.1871276402654', 'lng': '85.6189468974397', 'description': '<h4>KHORDHA</h4><p>PLOT NO 2491/5664/5887, NAYAGARH ROAD BEHIND OLD HOSPITAL,KHORDHA,ODISHA</p></br><a target=""_Blank"" href=""https://goo.gl/maps/Rg89wQfMhUNu29fi7"">Get Direction</a>', 'City': 'KHORDHA', 'State': 'Odisha' },
  { 'title': 'AKBARPUR', 'lat': '26.421956002757', 'lng': '82.535831382212', 'description': '<h4>AKBARPUR</h4><p>Plot No-531/1 and 532/2, Nai Sadak, Shahjadpur, Akbarpur, Ambedkar Nagar</p></br><a target="_Blank" href="https://goo.gl/maps/tPYKjkXVRuNCUZbF9">Get Direction</a>', 'City': 'AKBARPUR', 'State': 'Uttar Pradesh' },
  { 'title': 'ALLAHABAD', 'lat': '25.4555735775759', 'lng': '81.8359348550756', 'description': '<h4>ALLAHABAD</h4><p>32/1/5, Sp Marg, Vivekanand Chauraha, Civil Lines,Pyagraj</p></br><a target="_Blank" href="https://goo.gl/maps/px2FxTD8vDMbSYcYA">Get Direction</a>', 'City': 'ALLAHABAD', 'State': 'Uttar Pradesh' },
  { 'title': 'AZAMGARH', 'lat': '26.0640929274129', 'lng': '83.1828361975481', 'description': '<h4>AZAMGARH</h4><p>35-136, Opp Women Hospital, Matbarganj, Azamgarh</p></br><a target="_Blank" href="https://goo.gl/maps/E3674Vux5i391xp19">Get Direction</a>', 'City': 'AZAMGARH', 'State': 'Uttar Pradesh' },
  { 'title': 'MUNGRA BADSHAHPUR', 'lat': '25.6577912443857', 'lng': '82.1887988533604', 'description': '<h4>MUNGRA BADSHAHPUR</h4><p>Saryupatti Road, Sahabganj, Mungra Badshahpur</p></br><a target="_Blank" href="https://goo.gl/maps/9iWcbJzp6La9vWYF7">Get Direction</a>', 'City': 'MUNGRA BADSHAHPUR', 'State': 'Uttar Pradesh' },
  { 'title': 'BALRAMPUR', 'lat': '27.4334732166636', 'lng': '82.1767952294945', 'description': '<h4>BALRAMPUR</h4><p>TITU CINEMA COMPOUND, BAHRAICH ROAD</p></br><a target="_Blank" href="https://goo.gl/maps/DkE6P8cfuDnc7tq7A">Get Direction</a>', 'City': 'BALRAMPUR', 'State': 'Uttar Pradesh' },
  { 'title': 'BANDA', 'lat': '25.4727273029979', 'lng': '80.3326352128796', 'description': '<h4>BANDA</h4><p>Chowk Bazaar, Gular Naka, Banda</p></br><a target="_Blank" href="https://goo.gl/maps/C21ETcMhgHMtia9b8">Get Direction</a>', 'City': 'BANDA', 'State': 'Uttar Pradesh' },
  { 'title': 'BANDA 2', 'lat': '25.639484232173', 'lng': '80.4166010821959', 'description': '<h4>BANDA 2</h4><p>CIVIL LINE,CHILLA ROAD,MAUJA-LADAKA PURWA,BANDA</p></br><a target="_Blank" href="https://goo.gl/maps/bF3xQLHiATNXk2619">Get Direction</a>', 'City': 'BANDA 2', 'State': 'Uttar Pradesh' },
  { 'title': 'BASTI', 'lat': '26.7946556817573', 'lng': '82.7330546110557', 'description': '<h4>BASTI</h4><p>Gandhi Nagar Road, Near Shalimar Market, Gandhi Nagar</p></br><a target="_Blank" href="https://goo.gl/maps/hLNpCDtmAEqLz6Bg9">Get Direction</a>', 'City': 'BASTI', 'State': 'Uttar Pradesh' },
  { 'title': 'CHANDAULI', 'lat': '25.2606502464979', 'lng': '83.2647015713445', 'description': '<h4>CHANDAULI</h4><p>R.K. Palace, Ward No. 1, GT Road, Nehru Nagar</p></br><a target="_Blank" href="https://goo.gl/maps/ioMo3ET3oTGpmoRD6">Get Direction</a>', 'City': 'CHANDAULI', 'State': 'Uttar Pradesh' },
  { 'title': 'DEORIA', 'lat': '26.4915502273409', 'lng': '83.779670197557', 'description': '<h4>DEORIA</h4><p>BARHAJ ROAD, CIVIL LINES, NEAR SHAHID GATE, RAMNATH</p></br><a target="_Blank" href="https://goo.gl/maps/E8w23YoZbYgyxawm8">Get Direction</a>', 'City': 'DEORIA', 'State': 'Uttar Pradesh' },
  { 'title': 'FAIZABAD', 'lat': '26.785286046984', 'lng': '82.1420841110555', 'description': '<h4>FAIZABAD</h4><p>Tarang Road, Niyawan, Hasnu Katra</p></br><a target="_Blank" href="https://goo.gl/maps/kfve7zGrDSC1igvX6">Get Direction</a>', 'City': 'FAIZABAD', 'State': 'Uttar Pradesh' },
  { 'title': 'FARRUKHABAD', 'lat': '27.3868931948031', 'lng': '79.5873610822325', 'description': '<h4>FARRUKHABAD</h4><p>Lal Gate Couraha, Thandi Sarak, Near Sahara Bank</p></br><a target="_Blank" href="https://goo.gl/maps/QJBCXfbMyCwTFiA18">Get Direction</a>', 'City': 'FARRUKHABAD', 'State': 'Uttar Pradesh' },
  { 'title': 'FATEHPUR', 'lat': '25.920568723759', 'lng': '80.8099362822016', 'description': '<h4>FATEHPUR</h4><p>SH 13, Collector Ganj, Harihar Ganj, Fatehpur</p></br><a target="_Blank" href="https://goo.gl/maps/URG1PbhhFEhdnRJo8">Get Direction</a>', 'City': 'FATEHPUR', 'State': 'Uttar Pradesh' },
  { 'title': 'GHAZIABAD', 'lat': '28.6411745158577', 'lng': '77.4212262822602', 'description': '<h4>GHAZIABAD</h4><p>Plot No-1,Sauraday Nagar, By Pass, NH- 24, Vijay Nagar, Sarvodaya Nagar, Pratap Vihar</p></br><a target="_Blank" href="https://goo.gl/maps/yvnrJQfzuPWLpCpp6">Get Direction</a>', 'City': 'GHAZIABAD', 'State': 'Uttar Pradesh' },
  { 'title': 'GHAZIPUR', 'lat': '25.5826816093111', 'lng': '83.5795992128818', 'description': '<h4>GHAZIPUR</h4><p>J.P. Tower Kapurpur, Mishar Bazaar, Opp.- State Bank of India, Mishra Bazar, Aamghat</p></br><a target="_Blank" href="https://goo.gl/maps/PokwDMeTEUwUmUU9A">Get Direction</a>', 'City': 'GHAZIPUR', 'State': 'Uttar Pradesh' },
  { 'title': 'GHOSI', 'lat': '26.1044236443805', 'lng': '83.5450065245336', 'description': '<h4>GHOSI</h4><p>Near Gandhi Tiraha, Majhwara Road, Ghosi</p></br><a target="_Blank" href="https://goo.gl/maps/f4K6N28zkR3o3QAb7">Get Direction</a>', 'City': 'GHOSI', 'State': 'Uttar Pradesh' },
  { 'title': 'GOLA', 'lat': '28.0744019112377', 'lng': '80.4761399245757', 'description': '<h4>GOLA</h4><p>Near Shahnai Guest House, Lakhimur Rd, Gola Gokarannath</p></br><a target="_Blank" href="https://goo.gl/maps/vGKttbfHKG9Nzicb9">Get Direction</a>', 'City': 'GOLA', 'State': 'Uttar Pradesh' },
  { 'title': 'GORAKHPUR-1', 'lat': '26.7887466084428', 'lng': '83.3856604787839', 'description': '<h4>GORAKHPUR-1</h4><p>Plot 241, Medical College Rd, Near HN Singh Chauraha, Near Union Bank</p></br><a target="_Blank" href="https://goo.gl/maps/65hkc7dwoqUUrJuc7">Get Direction</a>', 'City': 'GORAKHPUR-1', 'State': 'Uttar Pradesh' },
  { 'title': 'GORAKHPUR-2', 'lat': '26.7502007833283', 'lng': '83.4087489336133', 'description': '<h4>GORAKHPUR-2</h4><p>Opposite Maruti Showroom, Near Power House Road, Mohadipur</p></br><a target="_Blank" href="https://goo.gl/maps/FHQjvQLzUAvknBfT7">Get Direction</a>', 'City': 'GORAKHPUR-2', 'State': 'Uttar Pradesh' },
  { 'title': 'JAUNPUR', 'lat': '25.7476556799019', 'lng': '82.6802948465298', 'description': '<h4>JAUNPUR</h4><p>Jaunpur Badlapur Padav, Jaunpur</p></br><a target="_Blank" href="https://goo.gl/maps/TWudk96Xi4pTae5K7">Get Direction</a>', 'City': 'JAUNPUR', 'State': 'Uttar Pradesh' },
  { 'title': 'KASIA', 'lat': '26.7412080989749', 'lng': '83.915646855234', 'description': '<h4>KASIA</h4><p>In Front Of Janta Hospital, Main Road, Gola Bazar, Kasia, Kushinagar</p></br><a target="_Blank" href="https://goo.gl/maps/KFPTekQnraGDZbJA9">Get Direction</a>', 'City': 'KASIA', 'State': 'Uttar Pradesh' },
  { 'title': 'KERAKAT', 'lat': '25.6408375085822', 'lng': '82.9205670975394', 'description': '<h4>KERAKAT</h4><p>428,SARAIBIRU,KERAKAT,NEAR HDFC BANK.KERAKAT</p></br><a target=""_Blank"" href=""https://goo.gl/maps/2LoZBaBd7ZgKgcSUA"">Get Direction</a>', 'City': 'KERAKAT', 'State': 'Uttar Pradesh' },
  { 'title': 'KUNDA', 'lat': '25.7218150245506', 'lng': '81.5096760840487', 'description': '<h4>KUNDA</h4><p>Gajraj Plaza, Saryeu Nagar, Infront of Kunda Police Station, Kunda, Pratapgarh</p></br><a target="_Blank" href="https://goo.gl/maps/GK8FUjjoLgHYehHq5">Get Direction</a>', 'City': 'KUNDA', 'State': 'Uttar Pradesh' },
  { 'title': 'LUCKNOW 1', 'lat': '26.8708771275779', 'lng': '80.9689087153435', 'description': '<h4>LUCKNOW 1</h4><p>M-31, Dhaal Near kukrailpul, Faizabad Rd, Shakti Nagar, Sanjay Gandhi Puram</p></br><a target="_Blank" href="https://goo.gl/maps/rHnBFHnfVupMC1tE8">Get Direction</a>', 'City': 'LUCKNOW 1', 'State': 'Uttar Pradesh' },
  { 'title': 'LUCKNOW 2', 'lat': '26.9143128294518', 'lng': '80.9558031468014', 'description': '<h4>LUCKNOW 2</h4><p>Shop No Qasmi Plaza,, 277, Kursi Rd, Tedhi Pulia, Sector-P, Vikas Nagar</p></br><a target="_Blank" href="https://goo.gl/maps/HsKtGki8eBbyHoFE8">Get Direction</a>', 'City': 'LUCKNOW 2', 'State': 'Uttar Pradesh' },
  { 'title': 'LUCKNOW 4', 'lat': '26.8737130405644', 'lng': '80.8912475', 'description': '<h4>LUCKNOW 4</h4><p>PLOT NO.-5, KHASRA NO  672, NAGARIA, NEAR KALIANGIRI TEMPLE, HARDOI ROAD, THAKUR GANJ</p></br><a target="_Blank" href="https://goo.gl/maps/LELtLYZmv8Cx6RpA7">Get Direction</a>', 'City': 'LUCKNOW 4', 'State': 'Uttar Pradesh' },
  { 'title': 'LUCKNOW 5', 'lat': '26.8025180886803', 'lng': '80.9197542582202', 'description': '<h4>LUCKNOW 5</h4><p>159, Bhadrukh, Sector L, Khazana Market Chauraha, Ashiyana</p></br><a target="_Blank" href="https://goo.gl/maps/tYMPKtpAKbp32Ros5">Get Direction</a>', 'City': 'LUCKNOW 5', 'State': 'Uttar Pradesh' },
  { 'title': 'LUCKNOW 6', 'lat': '26.9026790785011', 'lng': '80.9358904273947', 'description': '<h4>LUCKNOW 6</h4><p>Prabha Complex, Kursi Road, Near Vikas Nagar Road, Indira Nagar, Tiari Nagar Colony</p></br><a target="_Blank" href="https://goo.gl/maps/tXAjJ6K4yF1nADUq5">Get Direction</a>', 'City': 'LUCKNOW 6', 'State': 'Uttar Pradesh' },
  { 'title': 'MARIAHU', 'lat': '25.5963961297276', 'lng': '82.597219882195', 'description': '<h4>MARIAHU</h4><p>Malti Complex, Station Road, Near BNB PG College,Mariahu</p></br><a target="_Blank" href="https://goo.gl/maps/WygpVzdHGSDbkicE8">Get Direction</a>', 'City': 'MARIAHU', 'State': 'Uttar Pradesh' },
  { 'title': 'NANPARA', 'lat': '27.8594018895205', 'lng': '81.5064796110787', 'description': '<h4>NANPARA</h4><p>Nanpara Road, Near Kali Kunda Mandir, Nanpara, Bahraich</p></br><a target="_Blank" href="https://goo.gl/maps/64tieJj2tXE8N5FeA">Get Direction</a>', 'City': 'NANPARA', 'State': 'Uttar Pradesh' },
  { 'title': 'NAUGARH', 'lat': '27.2723948330646', 'lng': '83.0840413975736', 'description': '<h4>NAUGARH</h4><p>Civil Line Road, Sri Ganganagar, Tetri Bazar, Naugarh, Siddharth Nagar</p></br><a target="_Blank" href="https://goo.gl/maps/KUSWUGDRZenJsnWe7">Get Direction</a>', 'City': 'NAUGARH', 'State': 'Uttar Pradesh' },
  { 'title': 'PIPRAICH', 'lat': '26.8508400921622', 'lng': '83.5231351095788', 'description': '<h4>PIPRAICH</h4><p>WARM-4,RAMA NAGAR,KABADI ROAD,PIPRAICH</p></br><a target="_Blank" href="https://goo.gl/maps/N54YVsVS9vwHpubAA">Get Direction</a>', 'City': 'PIPRAICH', 'State': 'Uttar Pradesh' },
  { 'title': 'PRATAPGARH', 'lat': '25.9229532687756', 'lng': '81.9980482201576', 'description': '<h4>PRATAPGARH</h4><p>DDPLEX, Station Road, Bela, Pratapgarh</p></br><a target="_Blank" href="https://goo.gl/maps/KSpGDhBBy7WvuA5F9">Get Direction</a>', 'City': 'PRATAPGARH', 'State': 'Uttar Pradesh' },
  { 'title': 'RASRA', 'lat': '25.8622863385604', 'lng': '83.8476192851903', 'description': '<h4>RASRA</h4><p>Hita ka pura, Near State Bank of India, Rasra</p></br><a target="_Blank" href="https://goo.gl/maps/eVofZYQXYBDrEiyt9">Get Direction</a>', 'City': 'RASRA', 'State': 'Uttar Pradesh' },
  { 'title': 'RENUKOOT', 'lat': '24.2273948850928', 'lng': '83.0431165975114', 'description': '<h4>RENUKOOT</h4><p>Nanpara Hightech Morh, Near Anand Moter, Murdhwa, Renukoot, Sonbhadra</p></br><a target="_Blank" href="https://goo.gl/maps/kyBsHbxS9ZU9W5CEA">Get Direction</a>', 'City': 'RENUKOOT', 'State': 'Uttar Pradesh' },
  { 'title': 'SHAHGANJ', 'lat': '26.0622137933582', 'lng': '82.6956343128916', 'description': '<h4>SHAHGANJ</h4><p>Plot No-377, Bhadi, Shahganj, Jaunpur</p></br><a target="_Blank" href="https://goo.gl/maps/LCu4pPxNZVVipG6M6">Get Direction</a>', 'City': 'SHAHGANJ', 'State': 'Uttar Pradesh' },
  { 'title': 'AURAIYA', 'lat': '26.4578703064674', 'lng': '79.5150249994076', 'description': '<h4>AURAIYA</h4><p>Brahm Nagar, Badanpur,Near Chaturvedi Petrol Pump, Kanpur Road, Auraiya</p></br><a target="_Blank" href="https://goo.gl/maps/MSaz85LjZgQFsBZP6">Get Direction</a>', 'City': 'AURAIYA', 'State': 'Uttar Pradesh' },
  { 'title': 'MAHOBA', 'lat': '25.2931340910177', 'lng': '79.8814794975324', 'description': '<h4>MAHOBA</h4><p>Gandhi Nagar, Opposite Punjab National Bank, Mahoba</p></br><a target="_Blank" href="https://goo.gl/maps/YwmgYNoQZCLtdZg37">Get Direction</a>', 'City': 'MAHOBA', 'State': 'Uttar Pradesh' },
  { 'title': 'RATH', 'lat': '25.5905013003497', 'lng': '79.567825331394', 'description': '<h4>RATH</h4><p>Near Ram Leela Ground, Rath</p></br><a target="_Blank" href="https://goo.gl/maps/3tscxxmEYNiBLbza6">Get Direction</a>', 'City': 'RATH', 'State': 'Uttar Pradesh' },
  { 'title': 'HARIDWAR', 'lat': '29.9354844141689', 'lng': '78.136838641813', 'description': '<h4>HARIDWAR</h4><p>386, AWAS VIKAS COLONY,VIVEK VIHAR, NEAR LE GRAND HOTEL</p></br><a target="_Blank" href="https://goo.gl/maps/JhBo9jWkUz2545oPA">Get Direction</a>', 'City': 'HARIDWAR', 'State': 'Uttarakhand' },
  { 'title': 'PASIGHAT', 'lat': '28.0932592801924', 'lng': '95.3281409157974', 'description': '<h4>PASIGHAT</h4><p>TAKONG COMMERCIAL COMPLEX, NH-515(TURNING POINT) LOWER BASKOTA, DIST- EAST SIANG</p></br><a target="_Blank" href="https://goo.gl/maps/wqZmhZtNksfnYj2B6">Get Direction</a>', 'City': 'PASIGHAT', 'State': 'Arunachal Pradesh' },
  { 'title': 'NAHARLAGUN', 'lat': '27.1105497872864', 'lng': '93.694773347543', 'description': '<h4>NAHARLAGUN</h4><p>Sector-B, Near Old Bus Stand, Naharlagun</p></br><a target="_Blank" href="https://goo.gl/maps/S9kfi7Ji85jdPeDF8">Get Direction</a>', 'City': 'NAHARLAGUN', 'State': 'Arunachal Pradesh' },
  { 'title': 'NAGAON', 'lat': '26.3458736158603', 'lng': '92.6705497957027', 'description': '<h4>NAGAON</h4><p>Near K.N Devlopers, Khutikatia Haibargaon, Nagaon</p></br><a target="_Blank" href="https://goo.gl/maps/dcmgsnkV3RsG52XBA">Get Direction</a>', 'City': 'NAGAON', 'State': 'Assam' },
  { 'title': 'TINSUKIA', 'lat': '27.4996718321223', 'lng': '95.3677929957291', 'description': '<h4>TINSUKIA</h4><p>Ward No -04, Tinsukia Town , Street No -04, Gandhi Park Road, Tinsukia</p></br><a target="_Blank" href="https://goo.gl/maps/jNit1iivrSrPuCrA6">Get Direction</a>', 'City': 'TINSUKIA', 'State': 'Assam' },
  { 'title': 'UDALGURI', 'lat': '26.756546545452', 'lng': '92.0957955975626', 'description': '<h4>UDALGURI</h4><p>Dharmeshwar Daimari, Golmachowk</p></br><a target="_Blank" href="https://goo.gl/maps/dYaDNvBPpKLkEfLZ6">Get Direction</a>', 'City': 'UDALGURI', 'State': 'Assam' },
  { 'title': 'BONGAIGAON', 'lat': '26.4939295320751', 'lng': '90.5512938263929', 'description': '<h4>BONGAIGAON</h4><p>WARD-12,MOHALLA-CHAPAGURI,NEAR LOWER  HOSPITAL,NORTH BONGAIGAON P.O.</p></br><a target="_Blank" href="https://goo.gl/maps/yhvdE5a8FmcHtmMa9">Get Direction</a>', 'City': 'BONGAIGAON', 'State': 'Assam' },
  { 'title': 'JORHAT', 'lat': '26.7603776771881', 'lng': '94.2099544975627', 'description': '<h4>JORHAT</h4><p>Ward No.5, Block No.6, A.T. Road, Jorhat</p></br><a target="_Blank" href="https://goo.gl/maps/mXve5LnhFPKmseKFA">Get Direction</a>', 'City': 'JORHAT', 'State': 'Assam' },
  { 'title': 'SIBSAGAR', 'lat': '27.0558998377137', 'lng': '94.6232355625468', 'description': '<h4>SIBSAGAR</h4><p>A.T. ROAD, MAUZA NAGARMAHAL, WARD NO.6, DISTT. SIBSAGAR</p></br><a target="_Blank" href="https://goo.gl/maps/TXpNVmeSaLUX5VT9A">Get Direction</a>', 'City': 'SIBSAGAR', 'State': 'Assam' },
  { 'title': 'SENAPATI', 'lat': '25.2768416307511', 'lng': '94.0291043993833', 'description': '<h4>SENAPATI</h4><p>OLD MONDAY MARKET, NEAR POLICE CHECK POST, P.O. SENAPATI, SENAPATI DISTT.</p></br><a target="_Blank" href="https://goo.gl/maps/L3PkGTCXy7rPwYEe6">Get Direction</a>', 'City': 'SENAPATI', 'State': 'Manipur' },
  { 'title': 'JOWAI', 'lat': '25.5161608096827', 'lng': '92.1966267744823', 'description': '<h4>JOWAI</h4><p>BORBON,LUM SOO LUNG,WEST JAINTIA HILLS JOWAI</p></br><a target="_Blank" href="https://goo.gl/maps/dQEeEwTpUgkZPa586">Get Direction</a>', 'City': 'JOWAI', 'State': 'Meghalaya' },
  { 'title': 'KHELERIHAT', 'lat': '25.3581783745341', 'lng': '92.3690781846564', 'description': '<h4>KHELERIHAT</h4><p>KHLIEHRIAT WEST,KHLIEHRIAT P.O.,EAST JAINTIA,HILLS DISTRICT,MEGHALAYA</p></br><a target="_Blank" href="https://goo.gl/maps/B9PDYaRMmnepVoUu5">Get Direction</a>', 'City': 'KHELERIHAT', 'State': 'Meghalaya' },
  { 'title': 'DIMAPUR', 'lat': '25.9099616413486', 'lng': '93.7242330693129', 'description': '<h4>DIMAPUR</h4><p>NYAMO LOTHA ROAD, DIMAPUR, NAGALAND</p></br><a target="_Blank" href="https://goo.gl/maps/k2AUFGAHDCFHKH8H7">Get Direction</a>', 'City': 'DIMAPUR', 'State': 'Nagaland' },
  { 'title': 'THOUBAL', 'lat': '24.6391569945861', 'lng': '94.0019543920613', 'description': '<h4>THOUBAL</h4><p>REVENUE VILLAGE NO. 28, THOUBAL ACHOUBA, TEHSIL & DISTT. THOUBAL, MANIPUR</p></br><a target="_Blank" href="https://goo.gl/maps/No3HExt1k43Rugv97">Get Direction</a>', 'City': 'THOUBAL', 'State': 'Manipur' },

  ];
  markers: any = [];
  states:any = [];
  scity:any = [];
  filteredDatas: any = [];
  storeLocator: boolean = false;

  constructor(
    private storeService: CareerServiceService
  ) {

  }

  

  ngOnInit() {
    this.getStoreDetails();

  }

  refresh(){
    console.log('refresh button clicked.');
    //console.log(document.getElementById("state"));
    //$("#state").selectedIndex = "0";
    //$("#city").selectedIndex = "0";
    this.states = [];
    this.scity = [];
    this.getStoreDetails();
    // this.loadMap();
    this.storeLocator = true;
    // $(document).ready(function(){
    //     $('#refresh').trigger("reset");
    // })
    
  }

  getStoreDetails() {
    this.storeService.getStoreDetails()
      .subscribe((res: any) => {
        //console.log('get store details', res);
        this.markers = res;
        this.states = [];
        var datas:any = []
        res.forEach(el=>{
          datas.push(el.state);
        })
        //console.log('datas', datas);
        this.states = [...new Set(datas)];

        this.scity=[];
        var datas:any = []
        res.forEach(el=>{
          datas.push(el.city);
        })
        this.scity = [...new Set(datas)];

        this.renderMap();
      })
  }


  loadMap = () => {
    this.storeLocator = true;
    //console.log('markers', this.markers);
   var content = '<h4>ARARIA</h4><p>FM Complex, Hospital Road, Araria</p></br><a target="_Blank" href="https://goo.gl/maps/4cGiH9pdcwwxJJda6"'
    var map = new window['google'].maps.Map(this.mapElement.nativeElement, {
      // center: {lat: 24.5373, lng: 81.3042},
      center: { lat: parseFloat(this.markers[0].store_lat), lng: parseFloat(this.markers[0].store_lng) },
      zoom: 5
    });

    //console.log('markers1', this.markers);
    
    this.filteredDatas=[];
    for (let i = 0; i < this.markers.length; i++) {
     //console.log('inside for loop');
      var data = this.markers[i];
      //console.log('inside for loop1',data);
      this.filteredDatas.push(data);
      var marker = new window['google'].maps.Marker({
        position: { lat: parseFloat(data.store_lat), lng: parseFloat(data.store_lng) },
        map: map,
        title: data.store_name,
        draggable: true,
        animation: window['google'].maps.Animation.DROP,
      });
      var infoWindow = new window['google'].maps.InfoWindow();
      (function (marker, data) {
        window['google'].maps.event.addListener(marker, "click", function (e) {
          infoWindow.setContent(
            '<h4>' + data.store_name + '</h4><p>'+ data.Address + '</p></br><a target="_Blank" href="' + data.gmap_link + '">Get Direction</a>'
            );
          infoWindow.open(map, marker);
        });
      })(marker, data);
    };

    // var marker = new window['google'].maps.Marker({
    //   position: { lat: parseFloat(this.markers[0].lat), lng: parseFloat(this.markers[0].lng) },
    //   // position: {lat: 24.5373, lng: 81.3042},
    //   map: map,
    //   title: 'Hello World!',
    //   draggable: true,
    //   animation: window['google'].maps.Animation.DROP,
    // });

    var contentString = '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h3 id="thirdHeading" class="thirdHeading">W3path.com</h3>' +
      '<div id="bodyContent">' +
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>' +
      '</div>' +
      '</div>';

    var infowindow = new window['google'].maps.InfoWindow({
      content: contentString
    });

    marker.addListener('click', function () {
      infowindow.open(map, marker);
    });

    
  }
  renderMap() {

    window['initMap'] = () => {
      this.loadMap();
    }
    if (!window.document.getElementById('google-map-script')) {
      var s = window.document.createElement("script");
      s.id = "google-map-script";
      s.type = "text/javascript";
      s.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyC6v5-2uaq_wusHDktM9ILcqIrlPtnZgEk&callback=initMap";

      window.document.body.appendChild(s);
    } else {
      this.loadMap();
    }
  }

  getState() {
    console.log('getState');
  }

  stateChanged(e)
  {
    //console.log(e.target);
    //console.log(e.target.value);
    var a = this.markers.filter(ls => ls.state == e.target.value);
    //console.log(a);
    var datas:any = []
        a.forEach(el=>{
          datas.push(el.city);
        })
        this.scity = [...new Set(datas)];
    //this.scity = a.city;

    this.storeLocator = true;
    this.filteredDatas = [];
    var v1 = 0;
    for (let i = 0; i < this.markers.length; i++) {
      var data = this.markers[i];
      // console.log('data', data);
      // console.log('city',  $("#city").val());
      // console.log('state', $("#state").val());
      if (data.state == $("#state").val() ) {
        this.filteredDatas.push(data);
      }
      if (data.state == $("#state").val() ) {
      if (v1 == 0) {
        var mapOptions = {
          center: { lat: parseFloat(this.markers[i].store_lat), lng: parseFloat(this.markers[i].store_lng) },
          zoom: 6
          // center: new google.maps.LatLng(parseFloat(this.markers[i].lat), parseFloat(this.markers[i].lng)),
          // zoom: 6,
          // mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        // var infoWindow = new google.maps.InfoWindow();
        var infoWindow = new window['google'].maps.InfoWindow();
        //console.log('infoWindow', infoWindow);
        var map = new window['google'].maps.Map(this.mapElement.nativeElement, mapOptions)
        // var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);
      }
      v1 = 1 + i;

      // console.log('inside search', data);
      var myLatlng = new window['google'].maps.LatLng(parseFloat(data.store_lat), parseFloat(data.store_lng));
      var marker = new window['google'].maps.Marker({
        position: myLatlng,
        map: map,
        title: data.store_name,
        //icon: 'favicon.ico', 
      });
      (function (marker, data) {
        window['google'].maps.event.addListener(marker, "click", function (e) {
          infoWindow.setContent(
            '<h4>' + data.store_name + '</h4><p>'+ data.Address + '</p></br><a target="_Blank" href="' + data.gmap_link + '">Get Direction</a>'
          );
          infoWindow.open(map, marker);
        });
      })(marker, data);
    }
  }
  }
  search() {
    // alert($("#state").val());
    //console.log('window', window);
    this.storeLocator = true;
    this.filteredDatas = [];
    var v1 = 0;
    for (let i = 0; i < this.markers.length; i++) {
      var data = this.markers[i];
      // console.log('data', data);
      // console.log('city',  $("#city").val());
      // console.log('state', $("#state").val());
      if (data.state == $("#state").val()  && data.city == $("#city").val()) {
        this.filteredDatas.push(data);
      }
      // if (data.city == $("#city").val() && data.state == $("#state").val()) {

        //console.log('data', data);
        // this.markers.filter(ls => ls.City == )
        if (data.state == $("#state").val() && data.city == $("#city").val()) {

          if (v1 == 0) {
            var mapOptions = {
              center: { lat: parseFloat(this.markers[i].store_lat), lng: parseFloat(this.markers[i].store_lng) },
              zoom: 6
              // center: new google.maps.LatLng(parseFloat(this.markers[i].lat), parseFloat(this.markers[i].lng)),
              // zoom: 6,
              // mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            // var infoWindow = new google.maps.InfoWindow();
            var infoWindow = new window['google'].maps.InfoWindow();
            //console.log('infoWindow', infoWindow);
            var map = new window['google'].maps.Map(this.mapElement.nativeElement, mapOptions)
            // var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);
          }
          v1 = 1 + i;

          // console.log('inside search', data);
          var myLatlng = new window['google'].maps.LatLng(parseFloat(data.store_lat), parseFloat(data.store_lng));
          var marker = new window['google'].maps.Marker({
            position: myLatlng,
            map: map,
            title: data.store_name,
            icon: 'favicon.ico',
          });
          (function (marker, data) {
            window['google'].maps.event.addListener(marker, "click", function (e) {
              infoWindow.setContent(
                '<h4>' + data.store_name + '</h4><p>'+ data.Address + '</p></br><a target="_Blank" href="' + data.gmap_link + '">Get Direction</a>'
              );
              infoWindow.open(map, marker);
            });
          })(marker, data);
        }
      // }

    }

    //console.log('city', this.filteredDatas);

  }
}
