import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CareerServiceService } from 'src/app/career-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  store_form = new FormGroup({
    id: new FormControl(''),
    store_name: new FormControl(''),
    store_lat: new FormControl(''),
    store_lng: new FormControl(''),
    address: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    gmap_link: new FormControl(''),
    isActive: new FormControl('')
  })


  update: boolean = false;
  constructor(
    private careerService: CareerServiceService
  ) { }

  stores: any;
  storeId: any;
  activeStatus:boolean = false;

  ngOnInit() {
    this.getStoreDetails();
  }

  getStoreDetails() {
    this.careerService.getStoreDetails()
      .subscribe((res: any) => {
        this.stores = res;
        console.log('get store details', this.stores);
      })
  }

  create() {
    this.update = false;
    this.store_form.reset();
  }

  edit(data: any) {
    this.update = true;
    console.log('edit store form value', data);
    this.store_form.controls['id'].setValue(data.id);
    this.store_form.controls['store_name'].setValue(data.store_name);
    this.store_form.controls['store_lat'].setValue(data.store_lat);
    this.store_form.controls['store_lng'].setValue(data.store_lng);
    this.store_form.controls['address'].setValue(data.Address);
    this.store_form.controls['state'].setValue(data.state);
    this.store_form.controls['city'].setValue(data.city);
    this.store_form.controls['gmap_link'].setValue(data.gmap_link);
    this.store_form.controls['isActive'].setValue(true ? data.is_active == 1 : false);
    if(data.is_active == 1){
      this.activeStatus = true;
    }else if(data.is_active == 0){
      this.activeStatus = false;
    }
  }

  createStore() {
    console.log("store form data", this.store_form.value);
    this.careerService.saveStore({
      store_name: this.store_form.value.store_name,
      store_lat: this.store_form.value.store_lat,
      store_lng: this.store_form.value.store_lng,
      Address: this.store_form.value.address,
      state: this.store_form.value.state,
      city: this.store_form.value.city,
      gmap_link: this.store_form.value.gmap_link,
      is_active: 1 ? this.store_form.value.isActive == true : 0
    })
      .subscribe((res: any) => {
        console.log('response', res);
        $(document).ready(function () {
          $("#myModal").click();
        });
        this.getStoreDetails();
        this.store_form.reset();
      })
  }

  updateStore() {
    console.log('store form vaue', this.store_form.value);
    this.careerService.saveStore({
      id: this.store_form.value.id,
      store_name: this.store_form.value.store_name,
      store_lat: this.store_form.value.store_lat,
      store_lng: this.store_form.value.store_lng,
      Address: this.store_form.value.address,
      state: this.store_form.value.state,
      city: this.store_form.value.city,
      gmap_link: this.store_form.value.gmap_link,
      is_active: true ? this.store_form.value.isActive == 1 : false
    })
      .subscribe((res: any) => {
        console.log('response', res);
        $(document).ready(function () {
          $("#myModal").click();
        });
        this.getStoreDetails();
        this.store_form.reset();
      })
  }

}
