import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [

  // {
  //   path: 'admin-login', component: LoginSignupComponent,
  //   children: [
  //     {path: 'admin-login', component: LoginSignupComponent},
  //     {path: 'dashboard', redirectTo: 'DashboardComponent'}
  //   ]
  // },
  // { path: 'dashboard', component: AdminHomeComponent },
  // { path: 'view', component: ViewcvComponent },

  // {
  //   path: 'dashboard', component: PanelComponent, children: [
  //     {path: 'viewcv', redirectTo: '/viewcv'}
  //     // { path: 'viewcv', component: ViewcvComponent }
  //   ]
  // },


  // { path: 'home-main', component: HomeMainComponent, pathMatch: 'full' },
  // {
  //   path: 'about', component: AboutComponent,
  //   children: [
  //     { path: 'about', component: AboutComponent },
  //     { path: 'home', redirectTo: 'HomeMainComponent' }
  //   ]
  // },
  // { path: 'products', component: ProductsComponent },
  // { path: 'contact', component: ContactComponent },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent },
  // {
  //   path: 'career', component: CareerComponent,
  //   children: [
  //     { path: 'career', component: CareerComponent },
  //     { path: 'careerdetails', redirectTo: '/careerdetails' },
  //   ]
  // },
  // { path: 'storelocator', component: StoreLocatorComponent },
  // { path: 'careerdetails', component: CareerDetailsComponent },
  // {
  //   path: 'media', component: MediaComponent,
  //   children: [
  //     { path: 'media', component: MediaComponent },
  //     { path: 'home', redirectTo: 'HomeMainComponent' }
  //   ]

  // },
  // { path: '', redirectTo: 'home-main', pathMatch: 'full' },
  // { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
