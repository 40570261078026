import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CareerDataSharingService } from '../../career-data-sharing.service';
import { CareerServiceService } from '../../career-service.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css'],
  providers: [CareerServiceService, CareerDataSharingService]
})
export class CareerComponent implements OnInit {

  mycareer: any;
  noJob: boolean = false;

  static parameters = [CareerServiceService, CareerDataSharingService, Router];

  constructor(
    private _httpclient: CareerServiceService,
    private dataSharing: CareerDataSharingService,
    private router: Router
  ) { }


  ngOnInit() {

    this._httpclient.careerdata()
      .subscribe((data: any) => {
        console.log('data', data);
        // var Data = 
        this.mycareer = data;
        console.log('this.mycareer', this.mycareer);
        if (data.length > 0) {
          this.noJob = false;
        } else {
          this.noJob = true;
        }
        console.log('data ', this.mycareer);
      }
      )

  }

  careerApply(data: any) {
    console.log('apply now', data)
    //localStorage.setItem('job', data);
    localStorage.setItem('job', JSON.stringify(data));
    this.dataSharing.sendDesc(data);
    this.router.navigate(['/home-main/careerdetails']);
  }
  // routerLink="/careerdetails"




}
