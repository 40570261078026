import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel/panel.component';
import { FaqComponent } from './faq/faq.component';
import { ViewcvComponent } from './viewcv/viewcv.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderadminComponent } from './headeradmin/headeradmin.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { StoreComponent } from './store/store.component';



@NgModule({
  declarations: [
    PanelComponent,
    FaqComponent,
    ViewcvComponent,
    HeaderadminComponent,
    LoginSignupComponent,
    AdminHomeComponent,
    StoreComponent,
  ],
  exports: [
    PanelComponent,
    ViewcvComponent,
    LoginSignupComponent,
    AdminHomeComponent,
    StoreComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingModule
  ]


})

export class AdminModule {


}



