import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CareerServiceService } from 'src/app/career-service.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {

  mycareerdat: any;
  resume:boolean = false;


  constructor(
    private _httpclientdata: CareerServiceService,
    private router: Router
  ) {
    // this.loadScripts();
  }

  // loadScripts() {
  //   const externalScriptArray = [
  //     'assets/js/toggle.js',
  //     'assets/js/bootstrap.min.js',
  //     'assets/js/jquery.min.js'
  //   ];
  //   for (let i = 0; i < externalScriptArray.length; i++) {
  //     const scriptTag = document.createElement('script');
  //     scriptTag.src = externalScriptArray[i];
  //     scriptTag.type = 'text/javascript';
  //     scriptTag.async = false;
  //     scriptTag.charset = 'utf-8';
  //     document.getElementsByTagName('head')[0].appendChild(scriptTag);
  //   }
  // }

  ngOnInit() {
    $(document).ready(function () {
      $("#open-menu").click(function () {
        if ($('#page-container').hasClass('show-menu')) {
          $("#page-container").removeClass('show-menu');
        }
        else {
          $("#page-container").addClass('show-menu');
        }
      });
    });
  }


}
