import { Component, OnDestroy, OnInit } from '@angular/core';
import { CareerServiceService } from '../../career-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CareerDataSharingService } from '../../career-data-sharing.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';



@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.css'],
  // providers: [CareerDataSharingService]
})
export class CareerDetailsComponent implements OnInit, OnDestroy {

  public careerdetails = [];
  subscription: Subscription;

  customForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    // designation: new FormControl('', Validators.required),
    myfile: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required)

  });

  // static parameters = [CareerDataSharingService]
  constructor(
    private dataService: CareerDataSharingService,
    private careerService: CareerServiceService,
    private router: ActivatedRoute
  ) {
    this.subscription = this.dataService.desc$.subscribe((description: any) => {
      this.description = description;
      console.log('description', description);
    }, err => {
      console.log('error', err);
    })
  }

  currentDateTime;
  desc: any;
  fileInstance: any;
  description: any;
  department: any;
  ngOnInit() {
    var date = new Date();
    var fullDate = date.toISOString().slice(0, 10).replace(/-/g, '');
    var hour = date.getHours();
    var minute = date.getMinutes();
    var sec = date.getSeconds();
    this.currentDateTime = fullDate + hour + minute + sec;
    console.log('currentDateTime', this.currentDateTime);

    // console.log('datasharing service', this.dataService.desc$.subscribe());


    //this.description = localStorage.getItem('job');
    let jobD1 =  JSON.parse(localStorage.getItem('job'));
    //console.log('a',jobD1);
    this.description = jobD1.desc;
    this.department = jobD1.department;
    // console.log('route parameter', this.router.snapshot.params);
    // this.router.params.subscribe((param:Params)=>{
    //   console.log('parameter', param);
    // })
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelectedfile(e) {
    this.fileInstance = e.target.files[0];
  }
  onsubmit() {
    var data: any = {};
    var fd = new FormData();
    fd.append('file', this.fileInstance);
    data = this.customForm.value;
    var file;


    data.designation = this.description;
    data.department = this.department;
    this.careerService.uploadResume(fd)
      .subscribe((res: any) => {
        console.log('response', res);

        var path = res[0].split(/\\/);
        var filename = path[path.length - 1].split('.');
        // var fileExtention = filename[filename.length - 1].split('_');
        // console.log('PATH', path);
        // var renamedFileName = filename[0].concat('_').concat(fileExtention[1]).concat('.').concat(fileExtention[0]);
        data.myfile = path[path.length -1];
        console.log('data', data);
        this.saveCareerDetails(data);
      }, err => {
        console.log('Error', err);
      })
  }

  saveCareerDetails(data) {
    this.careerService.postCareerDetails(data)
      .subscribe((res: any) => {
        console.log('response', res);
        this.customForm.reset();
      }, err => {
        console.log('Error', err);
      })
  }
}
