import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';


import { HttpClientModule } from '@angular/common/http';
import { CareerServiceService } from '../app/career-service.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AdminModule } from './admin/admin.module';
import { MainModule } from './main/main.module';
import { SharedModule } from './shared/shared.module';
import { RewardprogrammComponent } from './rewardprogramm/rewardprogramm.component';
 




@NgModule({
  declarations: [
    AppComponent,
    RewardprogrammComponent,
    
  ],
  imports: [
    BrowserModule,
    MainModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AdminModule,
    SharedModule
  ],
  exports: [BrowserModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, CareerServiceService],
  bootstrap: [AppComponent]
})
export class AppModule {


}
