import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headeradmin',
  templateUrl: './headeradmin.component.html',
  styleUrls: ['./headeradmin.component.css']
})
export class HeaderadminComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('app-headeradmin');
  }

}
