import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { MainRoutingModule } from '../main/main-routing.module';
// import { NotfoundComponent } from '../shared/notfound/notfound.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import { PanelComponent } from './panel/panel.component';
import { StoreComponent } from './store/store.component';
import { ViewcvComponent } from './viewcv/viewcv.component';

const adminRoutes: Routes = [
    { path: 'admin-login', component: LoginSignupComponent },
    // { path: 'view', component: ViewcvComponent },
    {
        path: 'dashboard', component: PanelComponent, children: [
            { path: 'view', component: ViewcvComponent },
            { path: 'home', component: AdminHomeComponent },
            { path: 'store', component: StoreComponent }
        ]
    },
    // { path: '', redirectTo: 'dashboard/main', pathMatch: 'full' },
    // { path: '**', component: NotfoundComponent }

]

@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule]
})

export class AdminRoutingModule { }