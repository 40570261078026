import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider/slider.component';
import { BrandCollectionComponent } from './brand-collection/brand-collection.component';
import { OneindiaclubBannerComponent } from './oneindiaclub-banner/oneindiaclub-banner.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { HomeMainComponent } from './home-main/home-main.component';
import { FactSheetComponent } from './fact-sheet/fact-sheet.component';
import { AboutComponent } from './about/about.component';
import { ProductsComponent } from './products/products.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CareerComponent } from './career/career.component';
import { MediaComponent } from './media/media.component';
import { CareerDetailsComponent } from './career-details/career-details.component';
import { StoreLocatorComponent } from './store-locator/store-locator.component';
import { ContactComponent } from './contact/contact.component';
import { MainRoutingModule } from './main-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminModule } from '../admin/admin.module';


@NgModule({
  declarations: [
    SliderComponent,
    BrandCollectionComponent,
    OneindiaclubBannerComponent,
    OurTeamComponent,
    HomeMainComponent,
    FactSheetComponent,
    AboutComponent,
    ProductsComponent,
    PrivacyPolicyComponent,
    CareerComponent,
    MediaComponent,
    CareerDetailsComponent,
    StoreLocatorComponent,
    ContactComponent,
    DashboardComponent,
  ],
  exports: [
    SliderComponent,
    BrandCollectionComponent,
    OneindiaclubBannerComponent,
    OurTeamComponent,
    HomeMainComponent,
    FactSheetComponent,
    AboutComponent,
    ProductsComponent,
    PrivacyPolicyComponent,
    CareerComponent,
    MediaComponent,
    CareerDetailsComponent,
    StoreLocatorComponent,
    ContactComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule
  ]
})
export class MainModule { }
