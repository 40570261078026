import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
 
declare const test: any;

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.css']
})
export class HomeMainComponent implements OnInit {

  dashboard: boolean;
  navt: boolean = false;
  constructor(
    private router: Router
  ) {

    this.loadScripts();
  }
  ngOnInit() {
   
  }


  loadScripts() {
    const externalScriptArray = [
      '../assets/js/jquery.min.js',
      '../assets/js/jquery-migrate.min.js',
      '../assets/js/jquery-ui.min.js',
      '../assets/js/popper.min.js',
      '../assets/js/bootstrap.min.js',
      '../assets/js/modernizr.min.js',
      '../assets/js/particles.min.js',
      '../assets/js/particle-active.js',
      '../assets/js/theme-plugins.js',
      '../assets/js/main.js',
      'assets/js/menuhide.js'
    ];

    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }

  navAbout(){
    console.log('nav about clicked');
  }
  
}
