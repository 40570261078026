import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-collection',
  templateUrl: './brand-collection.component.html',
  styleUrls: ['./brand-collection.component.css']
})
export class BrandCollectionComponent implements OnInit {

  

  ngOnInit() {
  }
  constructor() {
    this.loadScripts();
  }
 
  loadScripts() {
    const externalScriptArray = [
      '../assets/js/jquery.min.js',
      '../assets/js/jquery-migrate.min.js',
      '../assets/js/jquery-ui.min.js',
      '../assets/js/popper.min.js',
      '../assets/js/bootstrap.min.js',
      '../assets/js/modernizr.min.js',
      '../assets/js/particles.min.js',
      '../assets/js/particle-active.js',
      '../assets/js/theme-plugins.js',
      '../assets/js/main.js',
    ];
    
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}
