import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.css']
})
export class LoginSignupComponent implements OnInit {

  login_form = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
  }

  get f() {
    return this.login_form.controls;
  }

  login() {
    // if (this.login_form.invalid) return;
    console.log('login', this.login_form.value);
    this.authService.login(this.login_form.value)
      .subscribe((res: any) => {
        console.log('response', res);
        this.router.navigate(['dashboard/home']).then((result: any) => {
          console.log('result', result);
        })
      })
  }

}
