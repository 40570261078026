import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CareerServiceService {

  constructor(private _http: HttpClient) { }

  url: any = environment.baseUrl;

  careerdata() {
    console.log('getCareerData url ', this.url);
    return this._http.get(this.url + 'Carrer/getcarrer')

  }

  postCareerDetails(data: any) {
    return this._http.post(this.url + 'Carrer/UploadCarrer', data);
  }

  uploadResume(fd: any) {
    return this._http.post(this.url + 'upload/PostFormData', fd);
  }

  postCareer(data: any) {
    return this._http.post(this.url + 'Carrer/CreateCarrer', data);
    // return this._http.post(this.url + 'Carrer/CreateCarrer', {}, { params: data });
  }

  deleteCareer(id: any) {
    return this._http.delete(this.url + 'Carrer/DeleteCarrer/' + id)
  }

  showResume() {
    return this._http.post(this.url + 'Carrer/ViewUploadCarrer', {});
  }

  getStoreDetails() {
    return this._http.get(this.url + 'Carrer/GetStoreDetails');
  }

  saveStore(data: any) {
    return this._http.post(this.url + 'Carrer/CreateStore', data);
  }

  getFiles(id:any, name:any){
    return this._http.get(this.url + 'Carrer/GetFiles/' + id + '/' + name);
  }
}
