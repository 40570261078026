import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CareerDataSharingService {

  // private _desc = new Subject<string>();
  private desc = new Subject<string>();


  desc$ = this.desc.asObservable();
 
  // console.log('desc$', desc$);
  constructor() {
  }

  sendDesc(description: string) {
    this.desc.next(description);
  }

}
