import { Component, OnInit } from '@angular/core';
import { CareerServiceService } from 'src/app/career-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-viewcv',
  templateUrl: './viewcv.component.html',
  styleUrls: ['./viewcv.component.css']
})
export class ViewcvComponent implements OnInit {

  constructor(
    private careerService: CareerServiceService
  ) { }

  resumes: any;

  ngOnInit() {
    this.showResume();
    // Basic example
    // $(document).ready(function () {
    //   $('#dtBasicExample').DataTable();
    // });
  }

  showResume() {
    console.log('show resume method called.');
    this.careerService.showResume()
      .subscribe((res: any) => {
        console.log('response', res);
        this.resumes = res;
        // console.log('type of resume', typeof res);
        // console.log('show resume', JSON.parse(res));
        // console.log('show Resume', this.resumes);
      })
  }

  sendFile(data:any){
    console.log('data', data);
    this.careerService.getFiles(data.id, data.myfile)
    .subscribe((res:any)=>{
      console.log('getfile response', res);
    })
  }

}
