import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { AdminRoutingModule } from '../admin/admin-routing.module';
import { LoginSignupComponent } from '../admin/login-signup/login-signup.component';
import { RewardprogrammComponent } from '../rewardprogramm/rewardprogramm.component';
import { NotfoundComponent } from '../shared/notfound/notfound.component';
import { AboutComponent } from './about/about.component';
import { CareerDetailsComponent } from './career-details/career-details.component';
import { CareerComponent } from './career/career.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeMainComponent } from './home-main/home-main.component';
import { MediaComponent } from './media/media.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

import { ProductsComponent } from './products/products.component';
import { StoreLocatorComponent } from './store-locator/store-locator.component';

const mainRoutes: Routes = [

    {
        path: 'home-main', component: HomeMainComponent, children: [
            { path: 'dashboard', component: DashboardComponent },
            { path: 'about', component: AboutComponent },
            { path: 'products', component: ProductsComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'career', component: CareerComponent },
            { path: 'careerdetails', component: CareerDetailsComponent },
            { path: 'storelocator', component: StoreLocatorComponent },
            { path: 'media', component: MediaComponent },
            { path: 'rewardsmain', component:RewardprogrammComponent }
        ]
    },
    { path: '', redirectTo: 'home-main/dashboard', pathMatch: 'full' },
    { path: 'admin-login', component: LoginSignupComponent },
    { path: '**', component: NotfoundComponent }


]
@NgModule({
    imports: [AdminRoutingModule, RouterModule.forChild(mainRoutes)],
    exports: [RouterModule]
})

export class MainRoutingModule { }