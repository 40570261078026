import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  url: any = environment.baseUrl;

  login(data: any) {
    console.log('data', data);
    return this.http.post(this.url + 'Carrer/login', data);
  }
}
