import { Component, OnInit } from '@angular/core';
import { Router, Params } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  title = 'oneindia-app';

  IsPanel:boolean = true;
  admin:boolean;

  constructor(
    private router: Router
  ){

  }

  ngOnInit(){
    
  }
  
}
 

